import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsPOSTApi, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";

export default function MeaningsUpload(props) {
  const history = useHistory()
  const [ID, setID] = React.useState("2095")
  const [reload, setReload] = React.useState(false)
  const [ImageArray, setImageArray] = React.useState([])
  const [ImageArrayReload, setImageArrayReload] = React.useState("")
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [CourseContent, setCourseContent] = React.useState([])
  const [CourseContentEdit, setCourseContentEdit] = React.useState(false)
  const [CourseEditData, setCourseEditData] = React.useState({})
  const [CourseEditKey, setCourseEditKey] = React.useState("")

  const EditInfo = () => {
    setReload(true)
    const data = {
      "meta": {
        meanings_list: JSON.stringify(CourseContent)
      }
    }
    AdsPUTApi(data, "meanings", ID)
      .then((res) => {
        setReload(false)
        setAlertShow(true)
      }).catch(err => {
        console.log(err)
        console.warn(err.response.data)
        console.log("Response Status: hello11", err.response.status);
        console.log("Response Headers:", err.response.headers);
        setReload(false)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
      })
  }
  useEffect(() => {
    fetchSliders()
  }, [])
  const fetchSliders = () => {
    setImageArrayReload(false)
    AdsGETApi({ _fields: "meta" }, "meanings")
      .then((res) => {
        if (res.data.length !== 0)
          if (res.data[0].meta.meanings_list.length !== 0)
            setCourseContent(JSON.parse(res.data[0].meta.meanings_list))
        setImageArrayReload(true)
      })
  }
  const pdfURL = '1-5.pdf';

  return (
    <div>
      <div>
        <div className="row" style={{ padding: "30px 30px" }}>
          <div className="col-md-8 button">
            <h2>Meaning</h2>
          </div>
          <div className="col-md-4" style={{ textAlign: 'right' }}>
            <a href="/">Dashboard</a> / <a href={slugs.all_books}>All Image</a>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Meanings Upload</center></h4>
            <table>
              <tbody>
                <tr>

                </tr>
              </tbody>
            </table>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Meaning
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 form-view mb-3">
            <h4><center>Course Content</center></h4>
            <div>
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4"
                    onClick={() => {
                      setCourseEditData({
                        "chapter_number": `${Object.keys(CourseContent).length + 1}`,
                        "title": "",
                        "description": "",
                        "meaning_list": "[]",
                      })
                      setCourseEditKey(`item-${Object.keys(CourseContent).length}`)

                      setCourseContentEdit(true)
                    }}
                  >
                    Add Chapter
                  </div>
                </div>
              </div>
              <div>
                <div className="row m-4">
                  <div>
                    <div className="row">
                      {Object.values(CourseContent).map((item, index) => {
                        return (
                          <div key={index} className="col-6">
                            <div
                              style={{
                                margin: "5px", padding: "5px 10px",
                                border: "2px solid black",
                                borderRadius: "10px",
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setCourseEditData(item)
                                setCourseEditKey(Object.keys(CourseContent)[index])
                                setCourseContentEdit(true)

                              }}
                            >
                              <div style={{ fontWeight: "bold", }}>
                                {item.title}
                              </div>

                              <div style={{}}>
                                {item.description}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        {/* {JSON.stringify(CourseContent)} */}

        {
          CourseContentEdit &&
          <section className="modal" >
            <div className="row">
              <div className="col-md-2 col-1"></div>
              <div className="col-md-8 col-10  shadow bg-white">
                <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                  <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                    onClick={() => {
                      setCourseContentEdit(false)
                      setCourseEditData([])
                      setCourseEditKey("")
                    }}
                  >X</div>

                  <CourseUploadComp
                    oneChapterData={CourseEditData} setCourseEditData={setCourseEditData}
                    keyValue={CourseEditKey} setCourseEditKey={setCourseEditKey}
                    CourseContent={CourseContent} setCourseContent={setCourseContent}
                    setCourseContentEdit={setCourseContentEdit}
                    CourseType={"Book"}
                  />
                </div>
              </div>
            </div>
          </section>
        }



        {reload &&
          <div className="modal" style={{ padding: "50vw" }}>
            <div className="dot-pulse"></div>
          </div>
        }
      </div>
    </div >
  )

}

const CourseUploadComp = ({
  oneChapterData, setCourseEditData,
  keyValue, setCourseEditKey, setCourseContentEdit,
  CourseContent, setCourseContent,
  CourseType
}) => {
  const [ChapterNumber, setChapterNumber] = React.useState(oneChapterData.chapter_number)
  const [Title, setTitle] = React.useState(oneChapterData.title)
  const [Description, setDescrition] = React.useState(oneChapterData.description)
  const [AudioList, setAudioList] = React.useState(JSON.parse(oneChapterData.meaning_list))
  const [English, setEnglish] = React.useState("")
  const [Nepali, setNepali] = React.useState("")
  const [Korean, setKorean] = React.useState("")
  const fileRefSingle = React.createRef();


  const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");
    const result = [];
    // console.log(headers, lines[0])
    for (let i = 1; i < lines.length; i++) {
      var obj = {};
      const currentLine = lines[i].replace("\r", "").split(",");
      // console.log(currentLine)
      for (let j = 0; j < headers.length; j++) {
        // obj[headers[j].trim()] = currentLine[j].trim();
        console.log(headers[j])
        var key = headers[j]
        obj = {
          ...obj,
          [key]: currentLine[j]
        }
      }
      // console.log(obj)

      result.push(obj);
    }
    // setImportData(result)
    return result;
  };
  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    e.preventDefault();
    const file = e.target.files[0]
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const jsonData = convertCSVToJson(csvOutput);
        const latestvalue = Object.values(AudioList).length + 1
        console.log(jsonData, csvOutput)
        var newData = AudioList
        for (let i = 0; i < jsonData.length; i++) {
          const jsonDatai = jsonData[i]
          // newData.push({
          newData = {
            ...newData,
            [`item-${Object.keys(AudioList).length + 1 + i}`]: {
              "korean": jsonDatai["korean"],
              "english": jsonDatai["english"],
              "nepali": jsonDatai["nepali"],
            }
          }
          // })
        }
        console.log(newData)
        setAudioList(newData)
      };
      fileReader.readAsText(file);
    };
  }

  return (
    <div>
      <div style={{ maxHeight: "60vh", overflowY: "scroll" }}>
        <table>
          <tbody>
            <tr>
              <td className="col-3">Chapter Number</td>
              <td className="col-6">
                {/* {ChapterNumber} */}
                <input className="input-common-4"
                  value={ChapterNumber} placeholder="Chapter Number"
                  onChange={(event) => { setChapterNumber(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Chapter Title</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Title} placeholder="Chapter Title"
                  onChange={(event) => { setTitle(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Chapter Description</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Description} placeholder="Chapter Description"
                  onChange={(event) => { setDescrition(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Grammer list</td>
              <td className="col-6">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        SN
                      </td>
                      <td>
                        Korean
                      </td>
                    
                      <td>
                        Nepali
                      </td>
                    </tr>
                    {Object.values(AudioList).map((item, index) => (
                      <tr key={index} style={{ cursor: "pointer", padding: "5px 0" }}>
                        <td>
                          {index + 1}
                        </td>
                        <td className="col-6">
                          <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                            value={item.korean} placeholder="Korean"
                            onChange={(event) => {
                              var pdf = AudioList;
                              pdf = {
                                ...pdf,
                                [`item-${index}`]: {
                                  "korean": `${event.target.value}`,
                                  "english": `${item.english}`,
                                  "nepali": `${item.nepali}`,
                                }
                              }
                              setAudioList(pdf)
                            }}
                          />
                        </td>
                        {/* <td>
                          <div style={{ width: "100%" }}>
                            <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                              value={item.english} placeholder="Pg"
                              onChange={(event) => {
                                var pdf = AudioList;
                                pdf = {
                                  ...pdf,
                                  [`item-${index}`]: {
                                    "korean": `${item.korean}`,
                                    "english": `${event.target.value}`,
                                    "nepali": `${item.nepali}`,
                                  }
                                }
                                setAudioList(pdf)
                              }}
                            />
                          </div>
                        </td> */}
                        <td>
                          <div style={{ width: "100%" }}>
                            <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                              value={item.nepali} placeholder="Pg"
                              onChange={(event) => {
                                var pdf = AudioList;
                                pdf = {
                                  ...pdf,
                                  [`item-${index}`]: {
                                    "korean": `${item.korean}`,
                                    "english": `${item.english}`,
                                    "nepali": `${event.target.value}`,
                                  }
                                }
                                setAudioList(pdf)
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr style={{ cursor: "pointer", padding: "5px 0" }}>
                      <td>
                        {Object.values(AudioList).length + 1}
                      </td>
                      {/* {AudioURL} */}
                      <td className="col-4">
                        <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                          value={Korean} placeholder="Korean"
                          onChange={(event) => {
                            setKorean(event.target.value)
                          }}
                        />
                      </td>
                      {/* <td className="col-3">
                        <div style={{ width: "100%" }}>
                          <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                            value={English} placeholder="English"
                            onChange={(event) => {
                              setEnglish(event.target.value)
                            }}
                          />
                        </div>
                      </td> */}
                      <td className="col-4">
                        <div style={{ width: "100%" }}>
                          <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                            value={Nepali} placeholder="Nepali"
                            onChange={(event) => {
                              setNepali(event.target.value)
                            }}
                          />
                        </div>
                      </td>
                      <td onClick={() => {
                        // setNumberofChapters(event.target.value)
                        var pdf = AudioList;
                        pdf = {
                          ...pdf,
                          [`item-${Object.keys(AudioList).length}`]: {
                            "korean": `${Korean}`,
                            "english": `${English}`,
                            "nepali": `${Nepali}`
                          }
                        }
                        setAudioList(pdf)
                        setEnglish("")
                        setKorean("")
                        setNepali("")
                        console.log(pdf)
                      }}>
                        Add
                      </td>
                    </tr>

                  </tbody>
                </table>

                {/* {Object.values(AudioList).map((item, index) => (
                  <div key={index}>
                    <div style={{ display: "inline" }}>
                      <div style={{ display: "inline-block" }}>
                        {index + 1}.
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <FileUploadChapters
                          PDfGroup={AudioList}
                          setPDFGroup={setAudioList}
                          ThisPDF={item}
                          index={index}
                          type={"audio"}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div style={{ display: "inline" }}>
                  <div style={{ display: "inline-block" }}>
                    {Object.values(AudioList).length + 1}.
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <FileUploadChapters
                      PDfGroup={AudioList}
                      setPDFGroup={setAudioList}
                      ThisPDF={""}
                      index={Object.values(AudioList).length}
                      type={"audio"}
                    />
                  </div>
                </div> */}
              </td>
            </tr>
            <tr>
              <td className="col-3">Grammer list</td>
              <td className="col-6">
                <div style={{ display: "none" }} >
                  <input ref={fileRefSingle}
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
                  onClick={() => {
                    fileRefSingle.current.click()
                  }}
                >Import CSV</div>
                {/* {JSON.stringify(Object.values(AudioList))} */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <center>
        <div style={{ width: "30%" }} className="sign-in-button-1"
          onClick={() => {
            setCourseContent({
              ...CourseContent,
              [keyValue]: {
                "chapter_number": `${ChapterNumber}`,
                "title": `${Title}`,
                "description":`${Description}`,
                "meaning_list": `${JSON.stringify(AudioList)}`,
              }
            })
            setCourseEditKey(``)
            setCourseContentEdit(false)
          }}
        >Done</div>
      </center>
    </div >
  )
}
